import React from 'react';
import Descriptor from '../../../Components/Descriptor';
import Image from '../../../Components/Image';

import COOLPRODUCTSLOGOpng from '../../../assets/CoolProducts/cool-products.png';
import COOLPRODUCTSLOGOwebp from '../../../assets/CoolProducts/cool-products.webp';

import styles from './CoolProducts.module.css';

const CoolProducts = () => {
  return (
    <div className={styles.coolProductsContainer}>
      <div className={styles.descriptorContainer}>
        <Descriptor
          titleStyle={styles.titleStyle}
          descriptionStyle={styles.descriptionStyle}
          knowMoreText="GET IN TOUCH"
          fill={false}
          descriptorAlignment={styles.coolProductsTextAlign}
          title="WE MENTOR ENTHUSIASTS AND WE BUILD COOL PROJECTS"
          description="Get mentored by us / Use our products / Join us in our journey"
          disabled
        />
      </div>
      <div className={styles.imageContainer}>
        <Image
          className={styles.image}
          src={COOLPRODUCTSLOGOpng}
          webpImage={COOLPRODUCTSLOGOwebp}
          alt="cool products"
        />
      </div>
    </div>
  );
};

export default CoolProducts;
